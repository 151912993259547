.Landing {
    height: 55vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.Landing-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 40px;
    color: white;
}

.Landing-content-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (min-width: 650px ) {
    
    .Landing {
        background-position: center center;
    }

    .Landing-content {
        align-items: flex-end;
        justify-content: center;
    }
}

h1{
    font-size: 35px;
}

p{
    font-size: 20px;
}
